import { Flex } from "antd";
import { FC } from "react";

import { Header } from "@/widgets/header";
import { UseCaseFooter } from "@/entities/use-cases";
import { COLOR_NAMES } from "@/shared/const/color-names.ts";
import { useToTop } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent, PageContainer, PageHeader } from "@/shared/ui";
import { CardTeam } from "./card-team";
import { Partnership } from "./partnership";

import annaMobile from "@/assets/landing/anna-mobile.png";
import anna from "@/assets/landing/anna.png";
import anton from "@/assets/landing/anton.png";
import arseniy from "@/assets/landing/arseniy.png";
import ivan from "@/assets/landing/ivan.png";
import kamilaMobile from "@/assets/landing/kamila-mobile.png";
import kamila from "@/assets/landing/kamila.png";
import michel from "@/assets/landing/michel.png";

import "./LandingTeam.scss";

export const LandingTeam: FC = () => {
	const { isTablet } = useMediaQueries();

	useToTop();

	return (
		<Flex vertical justify="center" className="team-page">
			<div style={{ background: COLOR_NAMES.BackgroundWhite, width: "100%" }}>
				<LandingContent>
					<Header />
				</LandingContent>
			</div>
			<PageHeader headerText="About" italicHeaderText="us"/>
			<PageContainer>
				<Flex vertical={isTablet} align="center" justify="center" gap={16}>
					<CardTeam
						fullName="Arseniy Seroka"
						positionName="CHIEF EXECUTIVE OFFICER"
						description="Arseniy is an experienced entrepreneur and learned film director. His love for technology and film is what motivates him to digitalize the creative industry."
						image={arseniy}
						href="https://www.linkedin.com/in/seroka/"
					/>
					<CardTeam
						fullName="Michel Perez"
						positionName="CHIEF PRODUCT OFFICER"
						description="Michel is an engineer that is obsessed with machine learning and loves building products that bring this powerful technology in the hand of everyday people."
						image={michel}
						href="https://www.linkedin.com/in/perezmichel"
					/>
					<CardTeam
						fullName="Ivan Markov"
						positionName="CHIEF TECHNOLOGY OFFICER"
						description="As a serial entrepreneur, Ivan is no stranger to building cool things with emerging tech. He cares about making a positive impact by making technology more accessible."
						image={ivan}
						href="https://linkedin.com/in/windstalker"
					/>
				</Flex>
				<Flex vertical={isTablet} align="center" justify="center" gap={16}>
					<CardTeam
						fullName="Antony Voryhalov"
						positionName="HEAD OF BUSINESS DEVELOPMENT"
						description="With leadership roles at Disney and Paramount, Anton brings 15+ years of experience in media and telecom. He excels in AI-driven storytelling and crafting strategies that merge creativity with technology to drive innovation and business growth."
						image={anton}
						href="https://www.linkedin.com/in/antonvoryhalov"
					/>
					<CardTeam
						fullName="Anna Zima"
						positionName="HEAD OF DESIGN"
						description="Anna embodies the epitome of creativity. Reminiscent of a character from the silver screen, she seamlessly transitions between personas, deftly altering the ambiance and aesthetic of design styles at will."
						image={isTablet ? annaMobile : anna}
						href="https://www.linkedin.com/in/anna-zima/"
						cover={isTablet}
					/>
					<CardTeam
						fullName="Kamilla Saifulina"
						positionName="CHIEF OF MARKETING"
						description="With over 10 years of experience in marketing within the IT industry, Kamilla specializes in branding, PR, and strategic communications. A passion for cinema makes Blooper a perfect match, blending professional expertise with a love for storytelling."
						image={isTablet ? kamilaMobile : kamila}
						href="https://www.linkedin.com/in/kamilla-saifulina-174112122/"
						cover={isTablet}
					/>
				</Flex>
			</PageContainer>
			<PageContainer>
				<Partnership />
			</PageContainer>
			<div className="full-width">
				<UseCaseFooter/>
			</div>
		</Flex>
	);
};
