import { useEffect } from "react";

export const useToTop = () => {
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const anchor = params.get("anchor");
		if (!anchor) {
			document.getElementById("root")?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);
};
