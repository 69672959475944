import { useAction, useAtom } from "@reatom/npm-react";
import { Button, ConfigProvider, Flex, Typography } from "antd";
import { FC, MutableRefObject, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	generateImageAtom,
	getStoryboardAction,
	SceneHeader,
	SceneShotGrid,
	storyboardEstimatedTimeAtom
} from "@/entities/storyboard";
import { COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { TextLoader } from "@/shared/ui";
import { CollapsedScene } from "./CollapsedScene";

import emptyStoryboard from "@/assets/storyboard/empty-storyboard.svg";

import "./StoryboardView.scss";

type Props = {
	targetRef: MutableRefObject<any>;
}

export const StoryboardView: FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [_generateImage, setGenerateImage] = useAtom(generateImageAtom);
	const [estimatedTime] = useAtom(storyboardEstimatedTimeAtom);

	const getStoryboard = useAction(getStoryboardAction);
	const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
	const [statuses] = useAtom(getStoryboardAction.statusesAtom);
	const onRedirect = () => {
		navigate(`/storyboard/${id}/script`);
	};

	useEffect(() => {
		if (id) {
			getStoryboard(id);
		}

		return () => {
			setGenerateImage({});
		};
	}, []);

	if (statuses.isPending) {
		return (
			<TextLoader time={estimatedTime} />
		);
	}

	return (
		<Flex vertical className="storyboard-view full-width gap-xs">
			{!storyboardList.length && (
				<Flex vertical align="center" justify="center" className="gap-s full-height">
					<img className="storyboard-view__image" src={emptyStoryboard} alt="Empty Storyboard" />
					<Flex vertical className="text-center gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>You haven't created storyboard yet</Typography.Text>
						<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Start creating script and storyboards</Typography.Text>
					</Flex>
					<Button type="primary" onClick={onRedirect}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Script</Typography.Text>
					</Button>
				</Flex>
			)}

			<Flex vertical className="gap-xs">
				<ConfigProvider theme={{
					components: {
						Collapse: {
							headerBg: COLOR_NAMES.MetalGray50,
							contentBg: COLOR_NAMES.MetalGray50,
							contentPadding: "16px 24px",
							headerPadding: "16px 24px"
						}
					}
				}}>
					{storyboardList.map((scene) => (
						<CollapsedScene scene={scene} key={scene.id} />
					))}
				</ConfigProvider>
			</Flex>
		</Flex>
	);
};

export const RenderTable: FC<Props> = ({ targetRef }) => {
	const [storyboardList] = useAtom(getStoryboardAction.dataAtom);

	return (
		<Flex ref={targetRef} vertical gap={16}>
			{storyboardList.map((scene) => (
				<Flex key={scene.idx} vertical style={{ background: "#F4F5F5", border: "1px solid #d9d9d9", borderRadius: 16 }}>
					<Flex style={{ padding: "16px 24px" }}>
						<SceneHeader
							scenesOrder={scene.scenesOrder}
							sceneId={scene.id}
							shotsOrder={scene.shots.map((shot) => shot.id)}
							locations={scene.locations}
							activeLocation="Cafe San Pietro"
							idx={scene.idx}
							title={scene.title}
							shotLength={scene.shots.length}
							projectLocation={scene.projectLocation}
						/>
					</Flex>
					<Flex wrap="wrap" className="gap-xs shots" style={{ padding: "16px 24px", borderTop: "1px solid #d9d9d9 " }}>
						{scene.shots.map((shot) => (
							<SceneShotGrid
								key={shot.id}
								sceneId={scene.id}
								style={{ minWidth: 300, maxWidth: 280 }}
								{...shot}
							/>
						))}
					</Flex>
				</Flex>
			))}
		</Flex>
	);
};
