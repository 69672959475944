import { Flex, Typography } from "antd";
import cn from "classnames";
import React, { FC, ReactNode } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./UseCaseTitle.scss";
import { LandingContent, TrialButton } from "@/shared/ui";

type Props = {
	isComingSoon: boolean;
	description: string;
	italicHeaderText: string;
	headerText: string | ReactNode;
	isBrItalic?: boolean;
	style?: React.CSSProperties;
}

export const UseCaseTitle: FC<Props> = ({
	description,
	italicHeaderText,
	headerText,
	isComingSoon,
	isBrItalic,
	style
}) => {
	const { isTablet, isLaptopS, isMobileXL } = useMediaQueries();

	return (
		<LandingContent>
			<Flex style={style} vertical className="use-case-header__title gap-xs text-center">
				{isComingSoon && (
					<Flex align="center" justify="center" style={{ margin: "0 auto", textTransform: "none" }} className="use-case-header__coming-soon">
						<Typography.Text className="use-case-header__coming-soon-title">
							Coming soon!
						</Typography.Text>
					</Flex>
				)}
				<Typography.Text className={cn("text-center", TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
					// [TEXT_CLASSNAMES.HeadlineH3]: isMobileXL
				})}>
					{headerText}
					{isBrItalic && (<br/>)}
					<span className="use-case-header__title--italic">{italicHeaderText}</span>
				</Typography.Text>
				<Typography.Text style={{ textTransform: "none" }} className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileXL
				})}>
					{description}
				</Typography.Text>
				<Flex justify="center" style={{ marginTop: 16 }}>
					<TrialButton />
				</Flex>
			</Flex>
		</LandingContent>
	);
};
