import { useAction, useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC, useEffect } from "react";

import { useParams } from "react-router-dom";
import { ShotDetails } from "@/features/shot-details";
import { ShotEditSidebar } from "@/features/shot-edit-sidebar";
import { ShotSelectionEditor } from "@/features/shot-selection-editor";
import { initShotsVersionsAction, ShotVersions } from "@/features/shot-versions";
import {
	getShotAction,
	getShotsInSceneAction,
	RightSidebarShot,
	shotSidebarViewAtom,
	shotsOrderLoadingAtom
} from "@/entities/shot-edit";
import { Spinner } from "@/shared/ui";

export const ShotEdit: FC = () => {
	const { id, shotId, sceneId } = useParams();

	const [sidebarView] = useAtom(shotSidebarViewAtom);
	const [status] = useAtom(getShotAction.statusesAtom);
	const [shotsOrderLoading] = useAtom(shotsOrderLoadingAtom);

	const initShot = useAction(getShotAction);
	const initShots = useAction(getShotsInSceneAction);
	const initShotsVersions = useAction(initShotsVersionsAction);

	useEffect(() => {
		if (id && shotId) {
			initShot({ projectId: id, shotId });
		}
	}, [id, shotId]);

	useEffect(() => {
		if (shotId) {
			initShotsVersions(shotId);
		}
	}, [shotId]);

	useEffect(() => {
		if (sceneId && id) {
			initShots({ sceneId, projectId: id });
		}
	}, []);

	if (status.isPending || shotsOrderLoading) {
		return (
			<Flex className="full-width full-height">
				<Spinner isOverlay />
			</Flex>
		);
	}

	return (
		<Flex className="full-width full-height">
			<ShotSelectionEditor />
			<RightSidebarShot>
				{sidebarView === "details" && <ShotDetails />}
				{sidebarView === "edit" && <ShotEditSidebar />}
				{sidebarView === "versions" && <ShotVersions />}
			</RightSidebarShot>
		</Flex>
	);
};
