import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { DropdownContent } from "@/widgets/header/dropdown-content";
import { LandingLayoutContext } from "@/widgets/landing-layout";
import arrowDown from "@/assets/shared/arrow_white_down.svg";
import close from "@/assets/shared/close_square_white.svg";
import menu from "@/assets/shared/menu.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { SOCIAL_MEDIA_LIST } from "@/shared/const/footer.ts";
import { LINKS } from "@/shared/const/links";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { TrialButton } from "@/shared/ui";

import "./BurgerMenu.scss";

type Keys = 0 | 1 | 2 | 3;
type State = Record<Keys, boolean>;

export const BurgerMenu: FC = () => {
	const { isMobileXL } = useMediaQueries();
	const { isOpen, toggleOpen } = useContext(LandingLayoutContext);
	const [openLinks, setOpenLinks] = useState<State>({ 0: false, 1: false, 2: false, 3: false });

	const toggleLinks = (index: Keys) => {
		setOpenLinks((prev) => ({
			...prev,
			[index]: !prev[index]
		}));
	};

	return (
		<Flex className="burger-menu">
			<div onClick={toggleOpen} role="button" className="burger-menu__button">
				<img className="burger-menu__img" src={isOpen ? close : menu} alt={isOpen ? "Close" : "Menu"} />
				{!isMobileXL && <Typography.Text>{isOpen ? "Close" : "Menu"}</Typography.Text>}
			</div>
			<Flex vertical justify="space-between" className={cn("burger-menu__content", {
				"burger-menu__open": isOpen
			})}>
				<Flex vertical>
					{LINKS.map((link, index) => Array.isArray(link.url)
						? (
							<Flex key={index.toString(36)} vertical>
								<Flex onClick={() => toggleLinks(index as Keys)} className="burger-menu__link" justify="space-between" align="center">
									<Typography.Text className={cn("burger-menu__link__text", TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextWhitePrimary)}>
										{link.title}
									</Typography.Text>
									<img className={cn("burger-menu__link__arrow cubic-animation", {
										"burger-menu__link__arrow-active": openLinks[index as Keys]
									})} src={arrowDown} alt="arrow" />
								</Flex>
								{openLinks[index as Keys] && (
									<Flex vertical className="gap-xs">
										{link.url.map((value, index) => (
											<DropdownContent {...value} className="burger-menu__link__case" onClose={toggleOpen} key={index} />
										))}
									</Flex>
								)}
							</Flex>
						)
						: (
							<NavLink onClick={toggleOpen} className="burger-menu__link" key={index.toString(36)} to={link.url}>
								<Flex justify="space-between" align="center">
									<Typography.Text className={cn("burger-menu__link__text", TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextWhitePrimary)}>
										{link.title}
									</Typography.Text>
								</Flex>
							</NavLink>
						))}
					<Flex style={{ marginTop: 36 }}>
						<TrialButton fullWidth/>
					</Flex>
				</Flex>
				<Flex className="gap-xs" justify="flex-end">
					{SOCIAL_MEDIA_LIST.map((social, index) => (
						<a href={social.link} target="_blank" key={index.toString(36)} className="footer__social-media flex-ctr-ctr">
							<img className="footer__img" src={social.icon} alt={social.alt}/>
						</a>
					))}
				</Flex>
			</Flex>
		</Flex>
	);
};
