import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { Gallery } from "react-grid-gallery";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { initShotsVersionsAction } from "../model";
import { DraggableOverlay } from "./DraggableOverlay.tsx";

import emptyVersions from "@/assets/shot-selection/empty-veriosns.svg";

export const ShotVersions: FC = () => {
	const [shotList] = useAtom(initShotsVersionsAction.dataAtom);

	if (!shotList.length) {
		return (
			<Flex vertical align="center" justify="flex-start" className="gap-s">
				<img src={emptyVersions} alt="empty versions" />
				<Typography.Text className={cn("text-center", TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
					Here we will store your versions of the images.
				</Typography.Text>
			</Flex>
		);
	}

	const images = shotList.map((image) => ({
		src: image?.bw_image_url_watermarked_compressed ?? "",
		width: image.width,
		height: image.height,
		alt: image?.id.toString() ?? "",
		key: image.id
	}));

	return (
		<Gallery
			margin={3}
			rowHeight={165}
			images={images}
			enableImageSelection={false}
			thumbnailImageComponent={DraggableOverlay}
		/>
	);
};
