import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, message, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import ReactLassoSelect from "react-lasso-select";
import { Point } from "react-lasso-select/lib/helpers";
import { useParams } from "react-router-dom";
import { TransformComponent } from "react-zoom-pan-pinch";

import { getShotAction } from "@/entities/shot-edit";
import { regenerateImageAction } from "@/entities/storyboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

import "./Content.scss";

export const Content: FC = () => {
	const [api, contextHolder] = message.useMessage();
	const [points, setPoints] = useState<Point[]>([]);
	const [shot] = useAtom(getShotAction.dataAtom);
	const [errorShot] = useAtom(regenerateImageAction.errorAtom);
	const regenerate = useAction(regenerateImageAction);
	const { id, shotId } = useParams();

	useEffect(() => {
		if (errorShot?.name) {
			const onClose = () => {
				api.destroy();
			};

			const btn = (
				<Flex>
					<Flex style={{ width: 250 }} vertical gap={6} align="flex-start">
						<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Something happened</Typography.Text>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Please try again</Typography.Text>
					</Flex>
					<Flex align="center">
						<Button type="primary" onClick={() => {
							if (id && shotId) {
								regenerate({ shotId, projectId: id }, true);
							}
							onClose();
						}}>
							Regenerate
						</Button>
					</Flex>
				</Flex>
			);
			api.error({
				content: btn,
				className: "regeneration-error",
				duration: 5
			});
		}
	}, [errorShot]);

	return (
		<Flex justify="center" align="center" className="flex-1">
			{contextHolder}
			<TransformComponent>
				<ReactLassoSelect
					imageStyle={{ width: "100%", height: "100%" }}
					value={points}
					src={shot?.image_url_compressed ? `${shot?.image_url_compressed}?no-cache=${Date.now()}` : ""}
					// src={testImage}
					onChange={value => {
						setPoints(value);
					}}
				/>
			</TransformComponent>
		</Flex>
	);
};
