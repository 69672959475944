import { useAtom } from "@reatom/npm-react";
import { FC } from "react";
import { PlanParams } from "@/pages/plan/lib";
import { getPossibleSubscriptionsAction } from "../../model";
import { PlanType } from "./PlanType.tsx";

type Props = {
	activePlanParams: PlanParams | null;
	onChange: (params: PlanParams) => void;
}

export const SubPlans: FC<Props> = ({ onChange, activePlanParams }) => {
	const [subscriptions] = useAtom(getPossibleSubscriptionsAction.dataAtom);
	if (!subscriptions.length) {
		return null;
	}

	return (
		<>
			{subscriptions.filter((el) => el.interval === "month").map((plan, idx) => (
				<PlanType
					key={idx.toString(36)}
					activePlanParams={activePlanParams}
					name="plan"
					termType={plan.id}
					title="Single User"
					description={`${plan.users} user, Short scripts (10000 characters max), ${plan.projects} projects maximum, ${plan.watermark ? "Watermarks" : "No watermarks"}`}
					interval={plan.interval}
					amount={plan.unit_amount}
					currency={plan.currency}
					onChange={onChange}
				/>
			))}
		</>
	);
};
