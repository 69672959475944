import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent, TrialButton } from "@/shared/ui";

import "./UseCaseBlock.scss";

type Props = {
	previewImg: string;
	title: string;
	description: string;
}

export const UseCaseBlock: FC<Props> = ({ previewImg, description, title }) => {
	const { isTablet, isMobileXL, isLaptopM } = useMediaQueries();

	return (
		<Flex className="use-case-block">
			<LandingContent>
				<Flex vertical={isTablet} className={cn("use-case-block__content", {
					"gap-m": isTablet,
					"gap-s": isMobileXL
				})}>
					<Flex className="use-case-block__content__img">
						<img src={previewImg} alt="preview" />
					</Flex>
					<Flex style={{ maxWidth: isTablet ? "100%" : isLaptopM ? 300 : 460 }} justify="center" vertical className={cn("use-case-block__content__text gap-m", {
						"gap-s": isTablet
					})}>
						<Flex vertical className={cn("gap-s", {
							"gap-xxs": isTablet
						})}>
							<Typography.Text
								className={cn(TEXT_CLASSNAMES.LPHeadlineH3, COLOR_CLASSNAMES.TextWhitePrimary, {
									[TEXT_CLASSNAMES.LPHeadlineH4]: isLaptopM,
									[TEXT_CLASSNAMES.LPHeadlineH5]: isTablet,
									[TEXT_CLASSNAMES.HeadlineH2]: isMobileXL
								})}
							>
								{title}
							</Typography.Text>
							<Typography.Text
								className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextWhitePrimary, {
									[TEXT_CLASSNAMES.SmRegular]: isLaptopM,
									[TEXT_CLASSNAMES.XsRegular]: isMobileXL
								})}
							>
								{description}
							</Typography.Text>
						</Flex>
						<TrialButton justify="flex-start" />
					</Flex>
				</Flex>
			</LandingContent>
		</Flex>
	);
};
