import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { PARTNERSHIP } from "@/shared/const/partnership.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./Partnership.scss";

export const Partnership: FC = () => {
	const { isTablet, isMobileXL } = useMediaQueries();

	return (
		<Flex className="gap-l" vertical align='center' justify="center">
			<Typography.Text style={{ textTransform: "uppercase" }} className={cn(TEXT_CLASSNAMES.LPHeadlineH3, {
				[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet,
				[TEXT_CLASSNAMES.LPHeadlineH5]: isMobileXL
			})}>Partnership</Typography.Text>
			<Flex wrap="wrap" justify="center" className="gap-s">
				{PARTNERSHIP.map(({ title, description, img }, index) => (
					<Flex vertical={isTablet} align="center" key={index.toString(36)} className="partnership gap-s">
						<img src={img} alt={title} />
						<Flex vertical gap={2}>
							<Typography.Text className={TEXT_CLASSNAMES.SmSemibold}>{title}</Typography.Text>
							<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>{description}</Typography.Text>
						</Flex>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};
