import { FC } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { RegisterForm } from "@/pages/register/ui/RegisterForm.tsx";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

import "./Register.scss";

export const Register: FC = () => {
	const navigate = useNavigate();
	const params = new URLSearchParams(window.location.search);
	const from = params.get("user_redirect_from");
	const isFromInstagram = from === "instagram";

	const handleRedirectToLogin = () => {
		if (from) {
			navigate({
				pathname: "/login",
				search: createSearchParams({
					user_redirect_from: from ?? ""
				}).toString()
			});
		} else {
			navigate("/login");
		}
	};

	const onDocumentRedirect = (href: string) => {
		const anchor = document.createElement("a");
		anchor.href = href;
		anchor.target = "_blank";
		anchor.click();
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				action={handleRedirectToLogin}
				title="Get started"
				questionText="Already have an account? "
				actionText="Sign in"
			>
				<RegisterForm isFromInstagram={isFromInstagram} onDocumentRedirect={onDocumentRedirect} />
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
