import { Flex } from "antd";
import { FC } from "react";
import { Header } from "@/widgets/header";
import { useToTop } from "@/shared/hooks";
import { FAQ, ProductShot, ProductSimplify, ProductStart, ProductSteps } from "./ui";

import "./Product.scss";

export const Product: FC = () => {
	useToTop();

	return (
		<Flex className="product" vertical>
			<Flex className="product__header">
				<Header />
			</Flex>
			<ProductStart />
			<ProductShot />
			<ProductSteps />
			<ProductSimplify />
			<FAQ />
		</Flex>
	);
};
