import { reatomAsync, withAbort, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";

import { callErrorAction } from "@/entities/notification";
import { getHighlightTheShotsResource } from "@/shared/api/script";
import { GetShotParams, GetShotsParams, getStoryboardShotResource, UploadImageResult } from "@/shared/api/storyboard";
import { ShotOrder, TShotSidebarView } from "../lib";

export const shotSidebarViewAtom = atom<TShotSidebarView>("details");

export const shotsOrderAtom = atom<ShotOrder[]>([], "shotsOrderAtom");

export const shotsOrderLoadingAtom = atom<boolean>(false, "shotsOrderLoadingAtom");

export const shotEditingImageUploadLoadingAtom = atom<boolean>(false, "shotEditingImageUploadLoading");

export const getShotsInSceneAction = action(async (ctx, params: GetShotsParams) => {
	try {
		shotsOrderLoadingAtom(ctx, true);
		const controller = new AbortController();
		const script = await getHighlightTheShotsResource(params.projectId, controller);

		const scenesInfo = script.data.scenes_info[params.sceneId];
		const scenesOrder = script.data.scenes_order;
		const shotsOrder = scenesInfo.shots_order;

		shotsOrderAtom(ctx, shotsOrder.map((shotId) => ({
			sceneIdx: scenesOrder.findIndex((id) => id === params.sceneId),
			shotIdx: scenesInfo.shots_order.findIndex((id) => id === shotId),
			id: shotId
		})));
	} catch (err) {
		callErrorAction(ctx, err);
	} finally {
		shotsOrderLoadingAtom(ctx, false);
	}
});

export const getShotAction = reatomAsync(
	(ctx, params: GetShotParams) => getStoryboardShotResource(params, ctx.controller)
).pipe(
	withAbort(),
	withStatusesAtom(),
	withDataAtom(null, (_ctx, res) => res.data),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const updateShotAction = action((ctx, params: UploadImageResult) => {
	const shot = ctx.get(getShotAction.dataAtom);

	if (shot) {
		getShotAction.dataAtom(ctx, {
			...shot,
			image_url_compressed: params.bw_shot_image_watermarked_compressed,
			image_url: params.bw_shot_image_compressed
		});
	}
});
