import { Button, Flex, Input, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { useBehavior } from "../model";

export const PromoCode: FC = () => {
	const { isMobileL } = useMediaQueries();

	const {
		onApply,
		onSkip,
		setPromoCode,
		error,
		loading
	} = useBehavior();

	return (
		<BackgroundPreLogin>
			<LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
				<Flex align="center" className="full-width full-height">
					<Flex vertical gap={16}>
						<Flex vertical className="full-width gap-xs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH4, {
								[TEXT_CLASSNAMES.XsSemibold]: isMobileL
							})}>
								Make sure to apply your promo code now!
							</Typography.Text>
							<Flex className="gap-xs">
								<Input
									onChange={(event) => setPromoCode(event.target.value)}
									placeholder="Enter code here"
									disabled={loading}
								/>
							</Flex>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.CrazyOrange500)}>{error}</Typography.Text>
						</Flex>
						<Flex className="full-width" gap={8}>
							<Button
								loading={loading}
								className="full-width"
								onClick={onApply}
								type="primary"
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Apply</Typography.Text>
							</Button>
							<Button
								loading={loading}
								className="full-width"
								onClick={onSkip}
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Skip</Typography.Text>
							</Button>
						</Flex>
					</Flex>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
