import { ConfigProvider, Flex, Typography } from "antd";
import { Question } from "@/pages/product/ui/FAQ/Question.tsx";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";

import "./FAQ.scss";
import { PARTNERSHIP } from "@/shared/const/partnership.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent } from "@/shared/ui";
import cn from "classnames";

const faq = {
	1: {
		title: "What is Blooper?",
		description: {
			text: "Blooper is an innovative pre-production platform designed to simplify and accelerate the video production process. Whether you’re creating a short film, a commercial, or digital content, Blooper helps you turn ideas into actionable plans faster than ever before.",
			list: [{
				title: "With Blooper, you can:",
				type: "dots",
				list: [
					"Automatically generate storyboards from scripts, eliminating hours of manual sketching.",
					"Get tailored location suggestions that fit the mood and requirements of your scenes.",
					"Organize and streamline your entire pre-production workflow in one intuitive tool."
				]
			}],
			finalText: "Built by creators for creators, Blooper is your all-in-one solution for efficient, collaborative, and stress-free pre-production."
		}
	},
	2: {
		title: "Who is Blooper for?",
		description: {
			text: "Blooper is designed for anyone involved in video production who wants to save time and improve efficiency during the pre-production phase.",
			list: [{
				title: "Here’s who can benefit the most:",
				type: "dots",
				list: [
					<span><span className={TEXT_CLASSNAMES.SmSemibold}>Filmmakers and Directors:</span> Quickly visualize scripts as storyboards and plan scenes with ease.</span>,
					<span><span className={TEXT_CLASSNAMES.SmSemibold}>Content Creators and Influencers:</span> Simplify planning for videos, campaigns, and social media projects.</span>,
					<span><span className={TEXT_CLASSNAMES.SmSemibold}>Production Teams:</span> Centralize collaboration, streamline feedback, and keep everyone aligned.</span>,
					<span><span className={TEXT_CLASSNAMES.SmSemibold}>Agencies:</span> Speed up pre-production for ads and branded content while maintaining creative quality.</span>,
					<span><span className={TEXT_CLASSNAMES.SmSemibold}>Project Managers:</span> Track the progress of video projects, manage workflows, and generate reports to keep stakeholders informed and ensure everything stays on schedule.</span>
				]
			}],
			finalText: "Whether you’re working on a feature film, a commercial, or a digital campaign, Blooper is built to make pre-production faster, smarter, and easier for everyone involved in the process."
		}
	},
	3: {
		title: "What features does Blooper offer?",
		description: {
			text: "Blooper is packed with features designed to streamline and optimize every step of the pre-production process. ",
			list: [{
				title: "Here’s what you can expect:",
				type: "dots",
				list: [
					"Script-to-Storyboard Conversion: Automatically generate professional-quality storyboards from your script in minutes, eliminating manual sketching.",
					"Location Suggestions: Get tailored recommendations for shooting venues that match the mood and requirements of each scene.",
					"Script Breakdown: Organize your script into clear, actionable tasks like shot lists, props, and schedules to keep your production on track.",
					"Collaboration Tools: Share ideas, gather feedback, and keep your team aligned with centralized communication and real-time updates.",
					"Customizable Pitch Decks: Build tailored presentations for your project, complete with themes, layouts, and visual elements."
				]
			}],
			finalText: ""
		}
	},
	4: {
		title: "How can I start using Blooper?",
		description: {
			text: "",
			list: [{
				title: "Getting started with Blooper is simple:",
				type: "nums",
				list: [
					"Sign Up: Create an account by logging in with your email. It only takes a few minutes.",
					"Try the Free Trial: Explore Blooper’s features with a free trial to see how it can transform your pre-production process.",
					"Choose a Plan: Once your trial ends, pick the subscription plan that works best for your needs. Whether you’re an individual creator or part of a large team, there’s an option for you."
				]
			}],
			finalText: ""
		}
	},
	5: {
		title: "What are the pricing options?",
		description: {
			text: "Blooper offers flexible pricing plans to suit a variety of needs, whether you're an individual creator, a growing team, or a large enterprise.",
			list: [
				// {
				// 	title: "Single User Plan:",
				// 	type: "dots",
				// 	list: [
				// 		"$109/month",
				// 		"Ideal for individual creators.",
				// 		"Includes up to 5 projects, short scripts (10,000 characters max), and watermarked outputs."
				// 	]
				// },
				// {
				// 	title: "Business Plan:",
				// 	type: "dots",
				// 	list: [
				// 		"$219/month",
				// 		"Perfect for teams.",
				// 		"Supports up to 20 users, unlimited projects, optional watermarks, collaboration tools, custom characters, movie pitch functionality, and premium support."
				// 	]
				// },
				// {
				// 	title: "Enterprise Plan:",
				// 	type: "dots",
				// 	list: [
				// 		"Custom pricing.",
				// 		"Tailored for large organizations.",
				// 		"Includes unlimited users, unlimited projects, no watermarks, on-premise deployment, custom model training, multiple SAML configurations, data loss prevention integration, and HIPAA-compliant messaging and collaboration tools."
				// 	]
				// }
			],
			finalText: "Additionally, tailor-made solutions are available for companies with specific requirements. Whether you need unique integrations, custom workflows, or specialized support, Blooper can design a plan to match your needs perfectly."
		}
	},
	6: {
		title: "Is Blooper secure?",
		description: {
			text: "Blooper prioritizes the security of its clients by implementing robust, industry-standard measures across all aspects of its platform. Our infrastructure is built on secure cloud services, leveraging advanced encryption and rigorous access controls to safeguard client data. We continuously monitor for vulnerabilities and apply proactive security updates to protect against evolving threats. Additionally, Blooper undergoes regular audits and employs best practices in DevSecOps to ensure that security is seamlessly integrated into every stage of development.",
			list: [],
			finalText: ""
		}
	}
};

export const FAQ = () => {
	const { isTablet } = useMediaQueries();
	return (
		<ConfigProvider
			theme={{
				components: {
					Collapse: {
						headerPadding: "32px 32px",
						headerBg: COLOR_NAMES.BackgroundWhite,
						colorBorder: "none",
						contentPadding: "0 32px 32px 32px",
						borderRadiusLG: 16
					}
				}
			}}
		>
			<Flex className="faq" vertical>
				<LandingContent>
					<Flex className="gap-s full-width" vertical>
						<Typography className="faq__title">Blooper FAQs</Typography>
						<Flex vertical={isTablet} className="full-width gap-m">
							<Flex flex={1} vertical className="gap-s">
								<Question description={faq[1].description} title={faq[1].title} />
								<Question description={faq[2].description} title={faq[2].title} />
								<Question description={faq[3].description} title={faq[3].title} />
							</Flex>
							<Flex flex={1} vertical className="gap-s">
								<Question description={faq[4].description} title={faq[4].title} />
								<Question description={faq[5].description} title={faq[5].title} />
								<Question description={faq[6].description} title={faq[6].title} />
							</Flex>
						</Flex>
					</Flex>
				</LandingContent>
				<Flex justify="center" align="center" vertical className="gap-s">
					<Typography.Text style={{ textTransform: "uppercase" }} className={cn(TEXT_CLASSNAMES.HeadlineH3, COLOR_CLASSNAMES.TextSecondary)}>Partnership with</Typography.Text>
					<Flex justify="center" wrap="wrap" className='faq__partnership'>
						{PARTNERSHIP.map(({ imgBlack, title }, index) => (
							<img key={index.toString(36)} src={imgBlack} alt={title} />
						))}
					</Flex>
				</Flex>
			</Flex>
		</ConfigProvider>
	);
};
