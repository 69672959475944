import { useAction, useAtom } from "@reatom/npm-react";
import { callErrorAction } from "@/entities/notification";
import { promoCodeAtom } from "@/entities/plan";
import {
	logoutAction,
	sessionLoginAction,
	subscriptionAction,
	viewerRegisterAtom,
	viewerSubscriptionAtom
} from "@/entities/viewer";

import { getPossibleSubscriptionsAction } from "./planModel.ts";

export const useStore = () => {
	const callError = useAction(callErrorAction);
	const sessionLogin = useAction(sessionLoginAction);
	const getSubscription = useAction(subscriptionAction);
	const logout = useAction(logoutAction);
	const getPossibleSubscriptions = useAction(getPossibleSubscriptionsAction);

	const [possibleSubscriptions] = useAtom(getPossibleSubscriptionsAction.statusesAtom);
	const [promoCode] = useAtom(promoCodeAtom);
	const [viewer] = useAtom(viewerRegisterAtom);
	const [subscription] = useAtom(viewerSubscriptionAtom);

	return {
		callError,
		sessionLogin,
		subscription,
		getSubscription,
		logout,
		getPossibleSubscriptions,
		possibleSubscriptions,
		promoCode,
		viewer
	};
};
