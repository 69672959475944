// export function timeAgo (time: Date | number | string): number | Date {
// 	switch (typeof time) {
// 	case "number":
// 		break;
// 	case "string":
// 		time = +new Date(time);
// 		break;
// 	case "object":
// 		if (time.constructor === Date) time = time.getTime();
// 		break;
// 	default:
// 		time = +new Date();
// 	}
// 	const timeFormats = [
// 		[60, "seconds", 1], // 60
// 		[120, "1 minute ago", "1 minute from now"], // 60*2
// 		[3600, "minutes", 60], // 60*60, 60
// 		[7200, "1 hour ago", "1 hour from now"], // 60*60*2
// 		[86400, "hours", 3600], // 60*60*24, 60*60
// 		[172800, "Yesterday", "Tomorrow"], // 60*60*24*2
// 		[604800, "days", 86400], // 60*60*24*7, 60*60*24
// 		[1209600, "Last week", "Next week"], // 60*60*24*7*4*2
// 		[2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
// 		[4838400, "Last month", "Next month"], // 60*60*24*7*4*2
// 		[29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
// 		[58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
// 		[2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
// 		[5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
// 		[58060800000, "centuries", 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
// 	];
// 	let seconds = (+new Date() - time) / 1000;
// 	let token = "ago";
// 	let listChoice = 1;
//
// 	if (seconds === 0) {
// 		return "Just now";
// 	}
// 	if (seconds < 0) {
// 		seconds = Math.abs(seconds);
// 		token = "from now";
// 		listChoice = 2;
// 	}
// 	let i = 0;
// 	let format;
// 	// eslint-disable-next-line no-cond-assign
// 	while (format = timeFormats[i++]) {
// 		// @ts-ignore
// 		if (seconds < format[0]) {
// 			if (typeof format[2] === "string") { return format[listChoice]; } else { return Math.floor(seconds / format[2]) + " " + format[1] + " " + token; }
// 		}
// 	}
// 	return time;
// }

export function timeAgo (input: number | Date) {
	const date = (input instanceof Date) ? input : new Date(input);
	const formatter = new Intl.RelativeTimeFormat("en");
	const ranges = [
		["years", 3600 * 24 * 365],
		["months", 3600 * 24 * 30],
		["weeks", 3600 * 24 * 7],
		["days", 3600 * 24],
		["hours", 3600],
		["minutes", 60],
		["seconds", 1]
	] as const;
	const secondsElapsed = (date.getTime() - Date.now()) / 1000;

	for (const [rangeType, rangeVal] of ranges) {
		if (rangeVal < Math.abs(secondsElapsed)) {
			const delta = secondsElapsed / rangeVal;
			return formatter.format(Math.round(delta), rangeType);
		}
	}
}
