import { Flex, Typography } from "antd";
import cn from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowDown from "@/assets/shared/arrow_white_down.svg";
import logo from "@/assets/shared/logo_white.svg";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { INFO_LIST, SOCIAL_MEDIA_LIST } from "@/shared/const/footer.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent } from "@/shared/ui";

import "./Footer.scss";

type Keys = 0 | 1 | 2 | 3;
type State = Record<Keys, boolean>;

export const Footer = () => {
	const { isTablet, isLaptopS, isMobileXL } = useMediaQueries();
	const navigate = useNavigate();
	const [openLinks, setOpenLinks] = useState<State>({ 0: false, 1: false, 2: false, 3: false });

	const toggleLinks = (index: Keys) => {
		setOpenLinks((prev) => ({
			...prev,
			[index]: !prev[index]
		}));
	};

	const handleRedirect = (path: string) => {
		navigate(path);
	};

	const isShowDropdown = (index: number) => !isTablet || openLinks[index as Keys];

	return (
		<footer className="footer">
			<LandingContent>
				<Flex vertical gap={40} className="full-width">
					<Flex vertical={isTablet} gap={isTablet ? 32 : 0} justify="space-between" className="full-width">
						<Flex vertical className="gap-s">
							<img className="footer__logo" src={logo} alt="blooper"/>
							<Flex className="gap-xs">
								{SOCIAL_MEDIA_LIST.map((social, index) => (
									<a href={social.link} target="_blank" key={index.toString(36)} className="footer__social-media flex-ctr-ctr">
										<img className="footer__img" src={social.icon} alt={social.alt}/>
									</a>
								))}
							</Flex>
							{!isTablet && (
								<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary, "pointer")}>
									info@blooper.ai
								</Typography.Text>
							)}
						</Flex>
						<Flex vertical={isTablet} wrap="wrap" gap={isTablet ? 24 : isLaptopS ? 50 : 80}>
							{INFO_LIST.map((info, index) => (
								<Flex className="gap-s" vertical key={index.toString(36)}>
									<Flex onClick={() => toggleLinks(index as Keys)} justify={isTablet ? "space-between" : ""} align={isTablet ? "center" : ""} className={cn({
										"full-width": isTablet
									})}>
										<Typography.Text className={cn(TEXT_CLASSNAMES.XsSemibold, COLOR_CLASSNAMES.TextWhitePrimary, "footer__link")}>
											{info.title}
										</Typography.Text>
										{isTablet && (
											<img className={cn("burger-menu__link__arrow cubic-animation", {
												"burger-menu__link__arrow-active": openLinks[index as Keys]
											})} src={arrowDown} alt="arrow"/>
										)}
									</Flex>
									{isShowDropdown(index) && (
										<Flex className="gap-xs" vertical>
											{info.links.map((link, idx) => (
												<Flex onClick={() => handleRedirect(link.link)} className="gap-xxs pointer" align="center" key={idx.toString(36)}>
													{link.icon && (
														<img src={link.icon} alt={link.text} className="footer__img" />
													)}
													<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.MetalGray400)}>
														{link.text}
													</Typography.Text>
												</Flex>
											))}
										</Flex>
									)}
								</Flex>
							))}
						</Flex>
					</Flex>
					<div style={{ background: COLOR_NAMES.TextWhitePrimary, width: "100%", height: 1 }} />
					<Flex vertical={isMobileXL} gap={isMobileXL ? 8 : 0}>
						<Flex vertical={isTablet} gap={isTablet ? 8 : 0} className="full-width" justify="space-between">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>Blooper AG, Zugerstrasse 32, 6340 Baar, Switzerland</Typography.Text>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>© Blooper 2025. All rights reserved.</Typography.Text>
						</Flex>
						{isTablet && (
							<Typography.Text style={{ minWidth: 106 }} className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary, "pointer")}>
								info@blooper.ai
							</Typography.Text>
						)}
					</Flex>
				</Flex>
			</LandingContent>
		</footer>
	);
};
