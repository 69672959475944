import { Flex } from "antd";
import { FC } from "react";
import { Header } from "@/widgets/header";
import { UseCaseBlock, UseCaseContent, UseCaseFooter, UseCaseTitle, UseCaseWrapper } from "@/entities/use-cases";

import { useToTop } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent } from "@/shared/ui";
import alice from "@/assets/use-cases/script/alice.png";
import assistant from "@/assets/use-cases/script/assistant.png";
import breakdown from "@/assets/use-cases/script/breakdown.png";

import john from "@/assets/use-cases/script/john.png";
import mary from "@/assets/use-cases/script/mary.png";
import mike from "@/assets/use-cases/script/mike.png";
import preview from "@/assets/use-cases/script/preview.png";
import rob from "@/assets/use-cases/script/rob.png";

import "./UseCaseScript.scss";

export const UseCaseScript: FC = () => {
	const { isMobileXL } = useMediaQueries();
	useToTop();

	return (
		<Flex vertical>
			<LandingContent>
				<Header />
			</LandingContent>
			<UseCaseTitle
				isComingSoon={false}
				description="AI-powered script breakdowns turn projects into reality with speed and precision "
				italicHeaderText="effortless"
				headerText="Script breakdowns made "
			/>
			<UseCaseBlock
				previewImg={preview}
				title="Break Down Scripts"
				description="Whether you have an idea or a finished script, Blooper streamlines the process. Our Script Assistant structures concepts into clear screenplays, while Script Breakdown organizes shot lists, scenes, and schedules. Character Creation ensures every role is well-defined—getting your production ready faster."
			/>
			<UseCaseWrapper>
				<Flex vertical={isMobileXL} className="gap-m">
					<UseCaseContent
						flex={1}
						title="Script assistant"
						description="Upload your screenplay to Blooper and instantly receive a script breakdown. No script yet? - No problem. Simply input your project briefing, and Blooper will generate a draft for you in minutes."
					>
						<img className="script-block-img__1 image-contain" src={assistant} alt="script assistant" />
					</UseCaseContent>
					<UseCaseContent
						id="script"
						flex={1}
						title="Script breakdown"
						description="Define the shots of your movie by hand or let Blooper do it - you decide. Our AI-driven assistant analyzes your script and proposes a shot outline."
					>
						<img className="script-block-img__2 image-contain" src={breakdown} alt="script assistant"/>
					</UseCaseContent>
				</Flex>
				<Flex>
					<UseCaseContent
						flex={1}
						title="Character creation"
						description="Our AI assistant recognizes characters in your script and provides you with a selection of people to embody them in your storyboard."
					>
						<Flex style={{ position: "relative", width: "100%" }}>
							{isMobileXL && <div style={{ height: 170 }} />}
							<Flex className="script-block-img__container-3" justify="center">
								<img className="script-block-img__3" src={mike} alt="script assistant" />
								<img className="script-block-img__3" src={alice} alt="script assistant" />
								<img className="script-block-img__3" src={john} alt="script assistant" />
								<img className="script-block-img__3" src={mary} alt="script assistant" />
								<img className="script-block-img__3" src={rob} alt="script assistant" />
							</Flex>
						</Flex>
					</UseCaseContent>
				</Flex>
			</UseCaseWrapper>
			<UseCaseFooter
			/>
		</Flex>
	);
};
