import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import ReactGA from "react-ga4";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import { UseCaseDescription } from "../use-case-description";

import blooperExamplePDF from "@/assets/blooper-example.pdf";
// import pdfDownloadIcon from "@/assets/use-cases/storyboard/pdf-download-icon.svg";
// import pdfExportIcon from "@/assets/use-cases/storyboard/pdf-export-icon.svg";
// import pdfExportPlaceholder from "@/assets/use-cases/storyboard/pdf-export-placeholder.png";

interface IExamplePDF {
    actionName: string;
    buttonText: string;
    description: string
    title: string;
}

export const ExamplePdf: FC<IExamplePDF> = ({ actionName, buttonText, title, description }) => {
	const { isTablet, isMobileL, isMobileS, isLaptopS, isLaptopM } = useMediaQueries();
	const onButtonClick = () => {
		const link = document.createElement("a");
		link.href = blooperExamplePDF;
		link.download = "Blooper example.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		ReactGA.event({
			category: "pdf download",
			action: actionName
		});
	};

	return (
		<Flex vertical={isTablet} justify="center" align="center" className="gap-l full-width full-height">
			{!isTablet && (
				<div style={{
					position: "relative"
				}}>
					<Button style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)"
					}} onClick={onButtonClick} className="flex-ctr-ctr gap-4" type="primary">
						{!isMobileL && <img src="" alt="download" />}
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{buttonText}</Typography.Text>
					</Button>
					<img
						style={{
							maxWidth: isLaptopS ? "355px" : isLaptopM ? "470px" : "573px",
							maxHeight: isMobileS ? "278px" : isMobileL ? "370px" : isLaptopS ? "436x" : isLaptopM ? "546px" : "683px"
						}}
						className="landing-content-center__img"
						src=""
						alt={title}
					/>
				</div>
			)}
			<UseCaseDescription
				style={{
					maxWidth: isTablet ? "" : isLaptopS ? "440px" : "627px",
					width: "100%"
				}}
				img=""
				title={title}
				description={description}
			/>
			{isTablet && (
				<div style={{
					position: "relative"
				}}>
					<Button style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)"
					}} onClick={onButtonClick} className="flex-ctr-ctr gap-4" type="primary">
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{buttonText}</Typography.Text>
					</Button>
					<img
						style={{
							maxWidth: isLaptopS ? "355px" : isLaptopM ? "470px" : "573px",
							maxHeight: isMobileS ? "278px" : isMobileL ? "370px" : isLaptopS ? "436x" : isLaptopM ? "546px" : "683px"
						}}
						className="landing-content-center__img"
						src=""
						alt="Export to PDF, Sheets/Excel, etc.  "
					/>
				</div>
			)}
		</Flex>
	);
};
