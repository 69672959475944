import axios from "axios";
import { api } from "@/shared/api";
import {
	HistorySubscription,
	PaymentCardDetails,
	PaymentCredentials,
	Promo,
	SubscribeCustomer,
	Subscription,
	SubscriptionSuccess,
	UserSubscription
} from "@/shared/api/payment/types.ts";
import { TResponse } from "@/vite-env";

export const getPossibleSubscriptionsResource = (): TResponse<Subscription> =>
	axios.get(`${import.meta.env.VITE_API_URL}/payments/subscriptions/`);

export const subscribeCustomerResource = (params: SubscribeCustomer): TResponse<SubscriptionSuccess> => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/`,
		data: {
			blooper_subscription_id: params.productId,
			interval: params.interval,
			promocode: params.promoCode
		},
		headers: {
			Authorization: `Bearer ${params.token}`
		}
	});
};

export const subscribeTrialCustomerResource = (token: string): TResponse<unknown> => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/trial`,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const deleteCustomerTrialResource = (token: string): TResponse<unknown> => {
	return axios({
		method: "DELETE",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription`,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const getCustomerSubscriptionResource = (token: string): TResponse<UserSubscription> =>
	axios({
		method: "GET",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/`,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});

export const checkPromoCodeResource = (token: string, promo: string, subscriptionId: string): TResponse<Promo> => {
	return axios({
		method: "GET",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/promo`,
		params: {
			promocode: promo,
			blooper_subscription_id: subscriptionId
		},
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const getPaymentCredentialsResource = (token: string): TResponse<PaymentCredentials> => {
	return axios({
		method: "GET",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/payment_credentials`,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const applyPromoCodeResource = (token: string, code: string): TResponse<unknown> => {
	return axios({
		method: "PATCH",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/apply_promocode`,
		params: {
			promocode: code
		},
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};
type P = {
	"fail_url": string
	"success_url": string;
}
export const createSetupIntentResource = (token: string, params: P): TResponse<{ session_id: string }> => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/create_setup_intent`,
		data: params,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const updateTrialPaymentDetails = (token: string, sessionId: string): TResponse<unknown> => {
	return axios({
		method: "PATCH",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/payment_details`,
		data: {
			stripe_session_id: sessionId
		},
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

type T = {
	"blooper_subscription_id": number;
	"interval": string;
	"promocode": string;
}

type R = {
	"message": string;
	"trial_status": string;
}

export const moveFromTrialResource = (token: string, params: T): TResponse<R> => {
	return axios({
		method: "POST",
		url: `${import.meta.env.VITE_API_URL}/payments/subscription/from_trial`,
		data: params,
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
};

export const getAuthCustomerSubscriptionResource = (): TResponse<UserSubscription> =>
	api.get("/payments/subscription/");

export const getSubscriptionPaymentHistoryResource = (): TResponse<HistorySubscription[]> =>
	api.get("/payments/subscription/payment_history");

export const cancelSubscriptionResource = (): TResponse<unknown> =>
	api.delete("/payments/subscription");

export const renewSubscriptionResource = (): TResponse<unknown> =>
	api.patch("/payments/subscription/renew");

export const getPaymentDetails = (): TResponse<PaymentCardDetails> =>
	api.get("/payments/subscription/payment_details");

export const getPaymentCredential = (): TResponse<PaymentCredentials> =>
	api.get("/payments/subscription/payment_credentials");

export const updatePaymentDetails = (id: string) =>
	api.patch("/payments/subscription/payment_details", { payment_method_id: id });
