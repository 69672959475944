import script from "@/assets/shared/script.svg";
import storyboard from "@/assets/shared/storyboard.svg";
import video from "@/assets/shared/video.svg";

export const USE_CASES_LIST = [
	{
		img: script,
		link: "/use-case-script",
		description: "Script breakdowns"
	},
	{
		img: storyboard,
		link: "/use-case-storyboard",
		description: "Convert Scripts to Storyboards"
	},
	{
		img: video,
		link: "/use-case-video",
		description: "Movie Pitch Deck"
	}
];

export const LEGAL_LIST = [
	{
		description: "Terms of Service",
		link: "/terms-of-conditions",
		img: null
	},
	{
		description: "Privacy Policy",
		link: "/privacy-policy",
		img: null
	},
	{
		description: "Cookie Policy",
		link: "/cookie-policy",
		img: null
	},
	{
		description: "Acceptable Use Policy",
		link: "/acceptable-use-policy",
		img: null
	}
];

export const LINKS = [
	// {
	// 	title: "Product",
	// 	url: "/"
	// },
	{
		title: "Use cases",
		url: USE_CASES_LIST,
		isLaptop: true
	},
	// {
	// 	title: "Pricing",
	// 	url: "/pricing"
	// },
	// {
	// 	title: "Blog",
	// 	url: "/"
	// },
	{
		title: "Pricing",
		url: "/pricing",
		isLaptop: true
	},
	{
		title: "Our Team",
		url: "/team",
		isLaptop: false
	},
	{
		title: "Legal",
		url: LEGAL_LIST,
		isLaptop: false
	}
	// {
	// 	title: "Contact",
	// 	url: "/contacts"
	// }
];
