import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import { initShotsVersionsAction } from "@/features/shot-versions";
import { initShotsVersionsAction } from "@/features/shot-versions";
import { scrollToShotIdAtom } from "@/entities/script";
import { getNextShotId, getPrevShotId, getShotAction, shotsOrderAtom, updateShotAction } from "@/entities/shot-edit";
import { regenerateImageAction, updateRegenerateImageAction } from "@/entities/storyboard";
import { UploadImageResult } from "@/shared/api/storyboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { SidebarNavigation } from "@/shared/ui";
import { ShotDetailsInfo } from "./shot-details-info";

export const ShotDetails: FC = () => {
	const regenerateImage = useAction(regenerateImageAction);
	const updateRegenerateImage = useAction(updateRegenerateImageAction);
	const initShotsVersions = useAction(initShotsVersionsAction);
	const updateShot = useAction(updateShotAction);

	const [shot] = useAtom(getShotAction.dataAtom);
	const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
	const [shotsOrder] = useAtom(shotsOrderAtom);
	const { id, sceneId, shotId } = useParams();
	const navigate = useNavigate();
	const currentShot = shotsOrder.find((shot) => shot.id === shotId);
	const [_, setScrollToShotId] = useAtom(scrollToShotIdAtom);
	const isImg = !!shot?.image_url_compressed;

	const onNext = () => {
		const list = shotsOrder.map((shot) => ({ id: shot.id }));
		const nextId = getNextShotId(list, shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${nextId}`);
	};

	const onPrev = () => {
		const list = shotsOrder.map((shot) => ({ id: shot.id }));
		const prevId = getPrevShotId(list, shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${prevId}`);
	};

	if (!currentShot) {
		return null;
	}

	const onRegenerate = async () => {
		if (shotId && id && sceneId) {
			updateRegenerateImage({ shotId, loading: true, image: null, error: false });
			const data = await regenerateImage({ shotId, projectId: id }, isImg);
			const params: UploadImageResult = {
				bw_shot_image: data?.image_url ?? "",
				bw_shot_image_compressed: data?.image_url_compressed ?? "",
				bw_shot_image_watermarked: data?.image_url ?? "",
				bw_shot_image_watermarked_compressed: data?.image_url_compressed ?? "",
				id: 0
			};

			updateShot(params);
			updateRegenerateImage({ shotId, loading: false, image: shot?.image_url_compressed ?? null, error: false });
			initShotsVersions(shotId);
		}
	};

	const goToScript = () => {
		if (shotId) {
			setScrollToShotId(shotId);
			navigate(`/storyboard/${id}/script#${shotId}`, { state: { from: "shot-editing" } });
		}
	};

	return (
		<Flex vertical justify="space-between" className="full-height full-width">
			<Flex style={{ overflowY: "scroll" }} vertical justify="space-between" className="full-width gap-xs">
				<Flex vertical className="gap-xs">
					<SidebarNavigation
						title={`Scene ${currentShot.sceneIdx + 1}. Shot ${currentShot.shotIdx + 1}`}
						onNext={onNext}
						onPrev={onPrev}
					/>
					<ShotDetailsInfo />
				</Flex>
			</Flex>
			<Flex style={{ paddingTop: 8 }} vertical className="gap-xs">
				<Button disabled={regenerateImageStatus.isPending} type="primary" className="flex-ctr-ctr" onClick={onRegenerate}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{isImg ? "Regenerate" : "Generate"}</Typography.Text>
				</Button>
				<Button onClick={goToScript}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
						Show in the script
					</Typography.Text>
				</Button>
			</Flex>
		</Flex>
	);
};
