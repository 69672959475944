import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, Fragment, PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { NEWS, timeAgo } from "../../../utils";

import "./Sidebar.scss";

export const Sidebar: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { isLaptopS, isTablet, isMobileXL } = useMediaQueries();

	const handleClick = (id: string) => {
		navigate(`/news/${id}`);
	};

	const getTime = (endTime: number) => {
		return timeAgo(endTime);
	};

	return (
		<Flex vertical={isMobileXL} className={cn("gap-l", {
			"gap-m": isLaptopS,
			"gap-xxs": isMobileXL
		})}>
			<Flex vertical={!isMobileXL} className={cn("gap-s", "sidebar-news", {
				"gap-xxs": isMobileXL
			})}>
				{NEWS.map((el, index) => (
					<Fragment key={index.toString(36)}>
						<Flex
							align="center"
							onClick={() => handleClick(el.id)}
							className={cn("gap-s sidebar-news__item pointer", {
								"gap-xs": isTablet,
								"sidebar-news__item--active": (el.id === id) || (!id && index === NEWS.length - 1)
							})}
						>
							<Flex vertical className="gap-4">
								{!isTablet && (
									<Flex className="gap-s">
										<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.Green600)}>{el.type}</Typography.Text>
										<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{getTime(el.date)}</Typography.Text>
									</Flex>
								)}
								<Typography.Text className={cn(TEXT_CLASSNAMES.SmSemibold, {
									[TEXT_CLASSNAMES.XsSemibold]: isTablet,
									[TEXT_CLASSNAMES.XxsSemibold]: isMobileXL
								})}>{el.title}</Typography.Text>
							</Flex>
							{!isMobileXL && (
								<img style={{ width: 80, height: 80, borderRadius: 8 }} src={el.sidebarImg} alt="sidebar image"/>
							)}
						</Flex>
						{NEWS.length - 1 !== index && <div style={{ height: 1, background: COLOR_NAMES.MetalGray300 }} />}
					</Fragment>
				))}
			</Flex>
			<div style={{ width: 1, background: COLOR_NAMES.MetalGray300 }} />
			{children}
		</Flex>
	);
};
