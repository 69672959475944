import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";
import { callErrorAction } from "@/entities/notification";
import { getShotImagesVersionsResource } from "@/shared/api/storyboard";
import { getImgSize } from "@/shared/methods";

export const initShotsVersionsAction = reatomAsync(async (_ctx, shotId: string) => {
	const { data } = await getShotImagesVersionsResource(shotId);

	const imagesPromises = data.map(async (image) => {
		const { width, height } = await getImgSize(image.bw_image_url_watermarked_compressed);

		return {
			...image,
			width,
			height
		};
	});

	const images = await Promise.all(imagesPromises);

	return images;
}, "initShotsVersions").pipe(
	withDataAtom([], (_ctx, images) => images),
	withStatusesAtom(),
	withErrorAtom(callErrorAction)
);
