import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

type Props = {
	img: string;
	title: string;
	description: string;
	link: string;
}

export const ProductAction: FC<Props> = ({ img, title, description, link }) => {
	const { isLaptopM, isMobileXL } = useMediaQueries();
	return (
		<NavLink to={link}>
			<Flex vertical className="product-action gap-xs">
				<Flex align="center" className="gap-xs">
					<img width={32} height={32} src={img} alt={title}/>
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdSemibold, COLOR_CLASSNAMES.TextWhitePrimary, {
						[TEXT_CLASSNAMES.SmRegular]: isLaptopM
					})}>{title}</Typography.Text>
				</Flex>
				<Flex style={{ paddingLeft: 49 }} vertical className="gap-xs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.MetalGray400, {
						[TEXT_CLASSNAMES.SmRegular]: isLaptopM,
						[TEXT_CLASSNAMES.XsRegular]: isMobileXL
					})}>{description}</Typography.Text>
				</Flex>
			</Flex>
		</NavLink>
	);
};
