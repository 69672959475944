import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { BackgroundPreLogin, LoginWrapper, sessionLoginAction, viewerRegisterAtom } from "@/entities/viewer";
import success from "@/assets/shared/success.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { mobileCheck } from "@/shared/methods";

export const AlreadySubscribed = () => {
	const navigate = useNavigate();
	const sessionLogin = useAction(sessionLoginAction);
	const [viewer] = useAtom(viewerRegisterAtom);
	const isMobile = mobileCheck();

	const start = () => {
		if (viewer) {
			sessionLogin(viewer);
			navigate("/main/projects");
		}
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper title="" questionText="" actionText="" action={() => {}}>
				<Flex className="text-center" vertical gap={24}>
					<img src={success} alt="success" style={{ maxWidth: 200, margin: "0 auto" }}/>
					<Flex style={{ maxWidth: 390 }} vertical gap={8}>
						<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>You’re already subscribed!</Typography.Text>
					</Flex>
					{isMobile
						? (
							<Button style={{ height: 60 }} onClick={start}>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
									Start using the app <br/>
									from the desktop
								</Typography.Text>
							</Button>
						)
						: (
							<Button type="primary" onClick={start}>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Start using</Typography.Text>
							</Button>
						)}
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
