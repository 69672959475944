import { Flex } from "antd";
import { FC } from "react";

import { Header } from "@/widgets/header";
import { UseCaseBlock, UseCaseContent, UseCaseFooter, UseCaseTitle, UseCaseWrapper } from "@/entities/use-cases";

import { useToTop } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent } from "@/shared/ui";
import composition from "@/assets/use-cases/storyboard/composition.png";
import location from "@/assets/use-cases/storyboard/location.png";
import moodboard from "@/assets/use-cases/storyboard/moodboard.png";
import preview from "@/assets/use-cases/storyboard/preview.png";
import storyboard from "@/assets/use-cases/storyboard/storyboard.png";

import "./UseCaseStoryboard.scss";

export const UseCaseStoryboard: FC = () => {
	const { isMobileXL } = useMediaQueries();
	useToTop();

	return (
		<Flex vertical>
			<LandingContent>
				<Header />
			</LandingContent>
			<UseCaseTitle
				isComingSoon={false}
				description="With Blooper, directors and producers bring their artistic vision to life through seamless planning and storytelling"
				italicHeaderText="with AI"
				headerText="Convert Scripts to Storyboards Instantly "
				style={{ maxWidth: 720 }}
			/>
			<UseCaseBlock
				previewImg={preview}
				title="Detailed Storyboard"
				description="Transform your script into clear, professional storyboards in minutes. Blooper maps out every shot, camera angle, and scene detail, keeping your team aligned and focused on creativity while ensuring your vision is production-ready."
			/>
			<UseCaseWrapper>
				<Flex vertical={isMobileXL} className="gap-m">
					<UseCaseContent
						id="moodboard"
						flex={1}
						title="Props Planning Made Easy"
						description="Blooper identifies essential props, costumes, and set elements from your script. From furniture to costumes, every detail is listed and organized—saving time, reducing costs, and ensuring your scene looks exactly as envisioned."
					>
						<img src={moodboard} alt="moodboard" className="storyboard-block-img__1 image-contain" />
					</UseCaseContent>
					<UseCaseContent
						id="storyboard"
						flex={1}
						title="Storyboard Generation Made Effortless"
						description="Transform your script into a professional storyboard in minutes with Blooper. Visualize each scene with AI precision, ensuring every shot, angle, and transition aligns with your vision. Save time, reduce manual work, and keep your team aligned before filming begins"
					>
						<img src={storyboard} alt="storyboard" className="storyboard-block-img__2 image-contain" />
					</UseCaseContent>
				</Flex>
				<Flex vertical={isMobileXL} className="gap-m">
					<UseCaseContent
						flex={1}
						title="Smart Shot Compositions"
						description="Plan every shot with precision using Blooper. Adjust camera angles, framing, and composition to align with your creative vision. From eye-level intimacy to dynamic top-angle views, every detail is crafted to bring your story to life—ready before filming begins."
					>
						<img src={composition} alt="composition" className="storyboard-block-img__3 image-contain" />
					</UseCaseContent>
					<UseCaseContent
						id="location"
						flex={1}
						title="Scout locations"
						description="No more endless searches for the right spot. Blooper suggests tailored venues that match your scene's mood and requirements, freeing up your time for creative decisions."
					>
						<img src={location} alt="location" className="storyboard-block-img__3 image-contain" />
					</UseCaseContent>
				</Flex>
			</UseCaseWrapper>
			<UseCaseFooter
			/>
		</Flex>
	);
};
