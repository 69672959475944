import { useHover } from "ahooks";
import { Button, ConfigProvider, Dropdown, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useContext, useRef, useState } from "react";
import { createSearchParams, NavLink, useLocation, useNavigate } from "react-router-dom";

import { LandingLayoutContext } from "@/widgets/landing-layout";
import { getCredential } from "@/entities/viewer/lib/utils.ts";
import arrow from "@/assets/shared/arrow_right.svg";
import blackLogo from "@/assets/shared/black_blooper.svg";
import minBlackLogo from "@/assets/shared/black_logo_min.svg";
import greenLogo from "@/assets/shared/green_blooper.svg";

import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { LINKS } from "@/shared/const/links";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useWindowSize } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";

import { BurgerMenu } from "./burger-menu";
import { Circle } from "./Circle";
import "./Header.scss";

export const Header: FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { isOpen, toggleOpen } = useContext(LandingLayoutContext);
	const [isDropdown, setIsDropdown] = useState(false);
	const { width } = useWindowSize();
	const { isMobileXL } = useMediaQueries();
	const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);
	const isUseCase = pathname.includes("use");

	const toggleDropdown = (value?: boolean) => {
		setIsDropdown((prevState) => value ?? !prevState);
	};

	const onMouseEnter = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		toggleDropdown(true);
	};

	const onMouseLeave = () => {
		timeout.current = setTimeout(() => {
			toggleDropdown(false);
		}, 250);
	};

	const onClick = (path: string) => {
		if (isOpen) {
			toggleOpen();
		}

		if (isDropdown) {
			toggleDropdown();
		}

		navigate(path);
	};

	const login = () => {
		const viewer = getCredential()?.data;
		const isAuth = !!viewer?.key;

		if (isAuth) {
			navigate("/main/projects");
		} else {
			const params = new URLSearchParams(window.location.search);
			const from = params.get("user_redirect_from");

			if (from) {
				navigate({
					pathname: "/login",
					search: createSearchParams({
						user_redirect_from: from ?? ""
					}).toString()
				});
			} else {
				navigate("/login");
			}
		}
	};

	const items = (links: any[]) => {
		return links.map((link: any, index: number) => (
			{
				key: index,
				label: (() => {
					const ref = useRef(null);
					const isHover = useHover(ref);
					return (
						<Flex ref={ref} onClick={() => navigate(link.link)} align="center" gap={8} style={{
							borderBottom: link.isBorder ? "1px solid black" : undefined,
							width: "100%",
							padding: "4px 0"
						}}>
							<div className={cn("header--dot", {
								"header--dot--hover": isHover
							})} />
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								{link.description}
							</Typography.Text>
						</Flex>
					);
				})()
			}
		));
	};

	const getLogo = () => {
		if (isMobileXL) {
			if (isOpen) {
				return greenLogo;
			}

			return minBlackLogo;
		}

		return blackLogo;
	};

	return (
		<>
			<header className={cn("header", {
				header__mobile: isOpen
			})}>
				<Flex className="header__content" align="center" justify="space-between">
					<div role="button" onClick={() => onClick("/")}>
						<img className="header__contentGroup 16.svg__img pointer" src={getLogo()} alt="logo" />
					</div>
					<Flex align="center" className="header__links">
						{LINKS.filter((el) => el.isLaptop).map((link, index) => Array.isArray(link.url)
							? (
								<ConfigProvider key={index.toString(36)} theme={{
									components: {
										Dropdown: {
											colorBgElevated: COLOR_NAMES.BackgroundWhite
										}
									}
								}}>
									<Dropdown
										overlayStyle={{ border: "1px solid black", borderRadius: 8 }}
										menu={{ items: items(link.url) }}
									>
										<Flex
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
											role="button"
											align="center"
											key={index.toString(36)}
											className="button"
											style={{ display: "flex" }}
										>
											<div style={{ padding: 10 }}>
												<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
													{link.title}
												</Typography.Text>
											</div>
											<div className={cn("header__content__toggle cubic-animation", {
												"header__content__toggle-active": isDropdown,
												active: isUseCase
											})}>
												<img src={arrow} alt="arrow" />
											</div>
											<Circle />
											{/* <HeaderDropdown isActive={isDropdown} onClose={toggleDropdown} /> */}
										</Flex>
									</Dropdown>
								</ConfigProvider>
							)
							: (
								<Flex align="center" key={index.toString(36)} className="gap-xxs button">
									<div style={{ padding: 10 }}>
										<NavLink to={link.url}>
											<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
												{link.title}
											</Typography.Text>
										</NavLink>
									</div>
									<Circle/>
								</Flex>
							))}
						<Button onClick={login} className="header__button">
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextWhitePrimary)}>Login</Typography.Text>
						</Button>
					</Flex>
					<Flex align="center" className="gap-xs">
						{width <= 575 && (
							<BurgerMenu />
						)}
					</Flex>
				</Flex>
				{/* {isHalf && <div style={{ minWidth: "50%" }} />} */}
			</header>
		</>
	);
};
