import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { CURRENCY } from "@/pages/plan/lib";
import { PaymentCredentials } from "@/shared/api/payment";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { toDate } from "@/shared/methods";

import "./PaymentSchedule.scss";

type Props = {
	sub: PaymentCredentials;
	isToday?: boolean;
}

export const PaymentSchedule: FC<Props> = ({ sub, isToday = true }) => {
	const { isMobileL } = useMediaQueries();

	const schedule = [
		{ cost: sub?.upcoming_invoice[0].amount, date: toDate(sub?.upcoming_invoice[0].payment_date ?? 0) }
	];

	return (
		<Flex vertical className="gap-xs payment-schedule">
			<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH2, {
				[TEXT_CLASSNAMES.HeadlineH3]: isMobileL
			})}>Payment Schedule</Typography.Text>
			<Flex className="payment-schedule__list" vertical>
				{isToday && (
					<Flex className="payment-schedule__list__header payment-schedule__list__el">
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isMobileL
						})} style={{ flex: 1 }}>{CURRENCY[sub?.current_invoice.currency ?? "usd"]}{sub?.current_invoice.amount}</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isMobileL
						})} style={{ flex: 3 }}>Today</Typography.Text>
						<div style={{ flex: 1 }} />
					</Flex>
				)}
				{schedule.map(({ cost, date }, index) => (
					<Flex key={index.toString(36)} align="center" justify="space-between" className="payment-schedule__list__el">
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isMobileL
						})} style={{ flex: 1 }}>{CURRENCY[sub?.current_invoice.currency ?? "usd"]}{cost}</Typography.Text>
						{isMobileL
							? (
								<Flex justify="space-between" align="flex-end" vertical={isMobileL}>
									<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
										[TEXT_CLASSNAMES.SmRegular]: isMobileL
									})}>{date}</Typography.Text>
									<Typography.Text
										className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
									>
									Auto Scheduled
									</Typography.Text>
								</Flex>
							)
							: (
								<>
									<Typography.Text style={{ flex: 3 }} className={cn(TEXT_CLASSNAMES.MdRegular, {
										[TEXT_CLASSNAMES.SmRegular]: isMobileL
									})}>{date}</Typography.Text>
									<Typography.Text
										className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}
									>
									Auto Scheduled
									</Typography.Text>
								</>
							)}
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};
