import { Flex } from "antd";
import { FC } from "react";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { Title } from "./Title.tsx";
import preview from "@/assets/product/preview.png";

import "./ProductStart.scss";

export const ProductStart: FC = () => {
	const { isLaptopS } = useMediaQueries();

	return (
		<Flex className="product-start">
			<Flex vertical={isLaptopS} className="product-start__content">
				<Flex justify="center" vertical className="product-content">
					<Flex className="product-content__wrapper">
						<Title />
					</Flex>
				</Flex>
				<Flex className="product-preview">
					<img className="product-preview__img image-contain" src={preview} alt="preview" />
				</Flex>
			</Flex>
		</Flex>
	);
};
