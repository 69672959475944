import { useAction, useAtom } from "@reatom/npm-react";
import { notification } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { clearErrorAction, errorAtom } from "@/entities/notification";
import { viewerAtom, viewerSubscriptionAtom } from "@/entities/viewer";

export const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
	const [api, contextHolder] = notification.useNotification();
	const [viewer] = useAtom(viewerAtom);
	const isAuth = !!viewer;
	const navigate = useNavigate();
	const clearError = useAction(clearErrorAction);
	const [error] = useAtom(errorAtom);
	const [subscription] = useAtom(viewerSubscriptionAtom);

	useEffect(() => {
		if (error?.type === "axios-error") {
			if (error.error.response?.status === 502 || error.error.response?.status === 500) {
				if (isAuth) {
					navigate("/main/500");
				} else {
					navigate("/500");
				}
			} else if (subscription?.message !== "No subscription found") {
				api.error({
					message: `Error status ${error.error.response?.status}`,
					description: error.error.response?.data?.message
				});
			}
		}

		if (error?.type === "stock-error") {
			api.error({
				message: "Error status 500",
				description: error.error?.message ?? "Oops, something went wrong. Please try again later"
			});
		}

		return () => {
			clearError();
		};
	}, [error]);

	return (
		<>
			{contextHolder}
			{children}
		</>
	);
};
