import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./Step.scss";

type Props = {
	num: number;
	title: string;
	description: string;
	img: string;
	numImg: string;
	arrowImg: string;
	isComingSoon?: boolean
}

export const Step: FC<Props> = ({ num, description, title, img, numImg, arrowImg, isComingSoon }) => {
	const { isTablet, isMobileXL } = useMediaQueries();

	return (
		<Flex vertical={isTablet}>
			<Flex justify="center" align="center" className="product-step__text">
				<Flex vertical className="gap-m product-step__text--width" justify="center" align="flex-start">
					<Flex vertical align="start" className="gap-xs text-left">
						<img src={numImg} alt={num.toString()} />
						{isComingSoon && (
							<Flex align="center" justify="flex-start" style={{ textTransform: "none" }} className="use-case-header__coming-soon">
								<Typography.Text className="use-case-header__coming-soon-title">
									Coming soon!
								</Typography.Text>
							</Flex>
						)}
						{/* <Typography.Text className={cn(TEXT_CLASSNAMES.LgSemibold, COLOR_CLASSNAMES.TextWhitePrimary, "product-step__num")}>{num}</Typography.Text> */}
						<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH1, {
							[TEXT_CLASSNAMES.HeadlineH2]: isTablet
						})}>{title}</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isTablet,
							[TEXT_CLASSNAMES.XsRegular]: isMobileXL
						})}>{description}</Typography.Text>
					</Flex>
					{(!isTablet && !!arrowImg) && <img className="product-step__img pointer" src={arrowImg} alt="arrow"/>}
				</Flex>
			</Flex>
			<Flex align="center" justify="center" className="product-step__content" flex={3}>
				<img src={img} alt={title} className="product-step__preview"/>
			</Flex>
		</Flex>
	);
};
