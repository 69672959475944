import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { getTrialEndAction, sessionPreLoginAction, viewerAtom, viewerSubscriptionAtom } from "@/entities/viewer";
import closeIcon from "@/assets/shared/close_square.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import info from "@/assets/plan/info.svg";

import "./TrialNotification.scss";

export const TrialNotification: FC = () => {
	const [subscription] = useAtom(viewerSubscriptionAtom);
	const close = sessionStorage.getItem("trial");
	const navigate = useNavigate();
	const sessionPreLogin = useAction(sessionPreLoginAction);
	const [viewer] = useAtom(viewerAtom);

	const getTrialEnd = useAction(getTrialEndAction);

	const trial = getTrialEnd();

	const onClose = () => {
		sessionStorage.setItem("trial", "true");
	};

	const onRedirect = () => {
		if (viewer) {
			sessionPreLogin(viewer);
			navigate("/plan");
		}
	};

	if (subscription?.trial_status === "on_trial" && close !== "true") {
		return (
			<div className="trial-banner">
				<Flex justify="space-between" className="trial-banner__content">
					<Flex align="center" className="gap-xxs">
						<img src={info} alt="info"/>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Your trial period will expire in <span className={TEXT_CLASSNAMES.XsSemibold}>{trial?.days} days.</span></Typography.Text>
					</Flex>
					<Flex align="center" className="gap-xs">
						<Button onClick={onRedirect} type="primary">
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Choose plan</Typography.Text>
						</Button>
						<img onClick={onClose} className="pointer" src={closeIcon} alt="close"/>
					</Flex>
				</Flex>
			</div>
		);
	}

	return null;
};
