import { useAction, useAtom } from "@reatom/npm-react";
import { Flex, Form, Input, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import { viewerRegisterAtom } from "@/entities/viewer";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { DisableSubmitButton } from "@/shared/ui";

import { getPromoCodeAction, promoErrorAtom } from "../model";

type FieldType = {
	code: string;
}

type Props = {
	subscriptionId: string;
};

export const PromotionalCode: FC<Props> = ({ subscriptionId }) => {
	const [form] = Form.useForm<FieldType>();
	const [viewer] = useAtom(viewerRegisterAtom);
	const getPromoCode = useAction(getPromoCodeAction);
	const [error] = useAtom(promoErrorAtom);
	const { isMobileL } = useMediaQueries();

	const onApply = (data: FieldType) => {
		if (viewer?.key) {
			let code = data.code;

			if (data.code === "free-blooper-tier") {
				code = "FREE-BLOOPER-TIER";
			}

			getPromoCode(viewer.key, code, subscriptionId);
		}
	};

	return (
		<Flex vertical gap={16}>
			<Form onFinish={onApply} className="full-width" form={form}>
				<Flex vertical className="full-width gap-xs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH4, {
						[TEXT_CLASSNAMES.XsSemibold]: isMobileL
					})}>
						Do you have a promotional code?
					</Typography.Text>
					<Flex className="gap-xs">
						<Form.Item<FieldType> className="flex-1" name="code" rules={[{ required: true, message: " " }]}>
							<Input
								onChange={(event) => form.setFieldValue("code", event.target.value)}
								placeholder="Enter code here"
								style={{
									borderColor: error ? COLOR_NAMES.CrazyOrange500 : undefined
								}}
							/>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.CrazyOrange500)}>{error}</Typography.Text>
						</Form.Item>
						<DisableSubmitButton type="primary" form={form}>Apply</DisableSubmitButton>
					</Flex>
				</Flex>
			</Form>
		</Flex>
	);
};
