import { Flex } from "antd";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { NEWS } from "@/pages/news/utils";

import "./NewsContent.scss";

export const NewsContent: FC = () => {
	const { id } = useParams();

	const content = id ? NEWS.find((el) => el.id === id) : NEWS[NEWS.length - 1];

	return (
		<Flex vertical className="news-content gap-m">
			<img className="news-content__img" src={content?.contentImg} alt="content"/>
			<ReactMarkdown children={content?.contentUrl ?? ""}/>
		</Flex>
	);
};
