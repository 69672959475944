import { COLOR_NAMES } from "@/shared/const/color-names.ts";

export const colors = (values: boolean[]) => {
	switch (values.length) {
	case 0:
		return "";
	case 1:
		return COLOR_NAMES.HeartRed500;
	case 2:
		return COLOR_NAMES.CrazyOrange500;
	case 3:
		return COLOR_NAMES.Green500;
	default:
		return COLOR_NAMES.Green500;
	}
};

export const percent = (values: boolean[]) => {
	const val = colors(values);

	switch (val) {
	case COLOR_NAMES.HeartRed500:
		return 20;
	case COLOR_NAMES.CrazyOrange500:
		return 60;
	case COLOR_NAMES.Green500:
		return 100;
	default:
		return 0;
	}
};
