import { Flex } from "antd";
import { FC } from "react";
import { Step } from "@/pages/product/ui/ProductSteps/Step.tsx";
import arrow1 from "@/assets/product/arrow-1.svg";
import arrow2 from "@/assets/product/arrow-2.svg";
import arrow3 from "@/assets/product/arrow-3.svg";
import arrow4 from "@/assets/product/arrow-4.svg";
import arrow5 from "@/assets/product/arrow-5.svg";

import num1 from "@/assets/product/num-1.svg";
import num2 from "@/assets/product/num-2.svg";
import num3 from "@/assets/product/num-3.svg";
import num4 from "@/assets/product/num-4.svg";
import num5 from "@/assets/product/num-5.svg";
import num6 from "@/assets/product/num-6.svg";

import step1 from "@/assets/product/step-1.png";
import step2 from "@/assets/product/step-2.png";
import step3 from "@/assets/product/step-3.png";
import step4 from "@/assets/product/step-4.png";
import step5 from "@/assets/product/step-5.png";
import step6 from "@/assets/product/step-6.png";

const steps = [
	{
		img: step1,
		num: 1,
		title: "Effortless script breakdowns made simple",
		description: "Streamline your script breakdowns into detailed shot lists and production schedules, making your shoot planning smooth and easy.",
		numImg: num1,
		arrowImg: arrow1
	},
	{
		img: step2,
		num: 2,
		title: "Moodboards That Bring Ideas to Life",
		description: "Save time and energy by turning your creative vision into a shareable moodboard in minutes. Define the tone, style, and visual direction of your project to ensure everyone on your team is on the same page.",
		numImg: num2,
		arrowImg: arrow2
	},
	{
		img: step3,
		num: 3,
		title: "Scene-Specific Venue Suggestions",
		description: "Receive tailored recommendations for shooting venues that match the mood and requirements of each scene in your project.",
		numImg: num3,
		arrowImg: arrow3
	},
	{
		img: step4,
		num: 4,
		title: "Instantly transform scripts into storyboards with AI",
		description: "Turn your script into a visual storyboard in minutes. Blooper helps you quickly see how \n" +
			"your video will look before \n" +
			"you start filming",
		numImg: num4,
		arrowImg: arrow4
	},
	{
		img: step5,
		num: 5,
		title: "Generate \n" +
			"your pitch deck",
		description: "Effortlessly craft a tailored pitch deck for ads, commercials, \n" +
			"and campaigns. Fine-tune \n" +
			"the design, content, and layout to showcase your vision \n" +
			"with clarity.",
		numImg: num5,
		arrowImg: arrow5
	},
	{
		img: step6,
		num: 6,
		title: "Built for collaboration",
		description: "Simplify teamwork with Blooper: share ideas, give feedback, and ensure everyone stays aligned effortlessly—all in one tool",
		numImg: num6,
		arrowImg: "",
		isComingSoon: true
	}
];

export const ProductSteps: FC = () => {
	return (
		<Flex vertical className="product-step">
			{steps.map((step, idx) => (
				<Step {...step} key={idx.toString(36)} />
			))}
		</Flex>
	);
};
