import { useAtom } from "@reatom/npm-react";
import { Flex, Typography } from "antd";
import { FC } from "react";

import { TransformWrapper } from "react-zoom-pan-pinch";
import { shotEditingImageUploadLoadingAtom } from "@/entities/shot-edit";
import { regenerateImageAction } from "@/entities/storyboard";
import { DotTyping } from "@/shared/ui";

import { Actions } from "./actions";
import { Content } from "./content";
import { Header } from "./header";

import "./ShotSelectionEditor.scss";

export const ShotSelectionEditor: FC = () => {
	const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
	const [shotEditingImageUploadLoading] = useAtom(shotEditingImageUploadLoadingAtom);

	return (
		<TransformWrapper disabled>
			<Flex vertical className="shot-selection-editor full-width gap-s">
				<Header />
				{(regenerateImageStatus.isPending || shotEditingImageUploadLoading)
					? (
						<Flex gap={24} align="center" justify="center" className="full-height full-width">
							<DotTyping />
							<Typography.Text>Generating</Typography.Text>
						</Flex>
					)
					: (
						<Content />
					)}
				<Actions />
			</Flex>
		</TransformWrapper>
	);
};
