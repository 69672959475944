import { Flex, Typography } from "antd";
import { FC, PropsWithChildren, useEffect } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

import "./UseCaseContent.scss";
import cn from "classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

type Props = {
	flex: number;
	title: string;
	description: string;
	id?: string;
}

export const UseCaseWrapper: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex className="use-case-wrapper" vertical>
			{children}
		</Flex>
	);
};

export const UseCaseContent: FC<PropsWithChildren<Props>> = ({ flex, title, description, children, id }) => {
	const { isMobileXL } = useMediaQueries();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const anchor = params.get("anchor");

		if (anchor) {
			const element = document.getElementById(anchor);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, []);

	return (
		<Flex id={id} className="use-case-content" flex={flex}>
			<Flex vertical align="center" className="gap-s" style={{ margin: "0 auto" }}>
				<Flex vertical className="text-center gap-xs">
					<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH1, {
						[TEXT_CLASSNAMES.HeadlineH2]: isMobileXL
					})}>{title}</Typography.Text>
					<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, {
						[TEXT_CLASSNAMES.XsRegular]: isMobileXL
					})}>{description}</Typography.Text>
				</Flex>
				{children}
			</Flex>
		</Flex>
	);
};
