import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { sessionPreLoginAction, viewerAtom, viewerSubscriptionAtom } from "@/entities/viewer";
import { TRegisterKey } from "@/shared/api/auth";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";

export const TrialBanner: FC = () => {
	const navigate = useNavigate();
	const [viewer] = useAtom(viewerAtom);
	const sessionPreLogin = useAction(sessionPreLoginAction);
	const [subscription] = useAtom(viewerSubscriptionAtom);

	const onRedirect = () => {
		sessionPreLogin(viewer as TRegisterKey);
		navigate("/plan");
	};

	if (subscription?.message === "No subscription found" || (subscription?.message === "User has no subscription" && subscription?.trial_status === "past_trial")) {
		return (
			<Modal open={true} closeIcon={null} footer={[]}>
				<Flex vertical className="text-center gap-s">
					<Flex vertical className="gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH4}>Your trail period <br/> was ended.</Typography.Text>
						<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>Renew now to continue enjoying<br/> uninterrupted access to all features.</Typography.Text>
					</Flex>
					<Flex justify="center">
						<Button type="primary" onClick={onRedirect}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Choose plan
							</Typography.Text>
						</Button>
					</Flex>
				</Flex>
			</Modal>
		);
	}

	return null;
};
