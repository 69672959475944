import { Collapse, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, JSX, useState } from "react";
import minus from "@/assets/shared/minus.svg";
import plus from "@/assets/shared/plus_square.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

type Props = {
	title: string;
	description: {
		text: string;
		list: {
			title: string;
			type: string;
			list: (string | JSX.Element)[];
		}[];
		finalText: string;
	};
};

export const Question: FC<Props> = ({ title, description }) => {
	const { isLaptopM } = useMediaQueries();
	const [activeKey, setActiveKey] = useState("");
	const isActive = activeKey === "1";

	return (
		<Collapse
			onChange={(key) => {
				setActiveKey(key[0]);
			}}
			expandIconPosition="end"
			expandIcon={() => (
				<Flex justify="center" align="center">
					<img src={isActive ? minus : plus} width={32} height={32} alt="plus"/>
				</Flex>
			)}
			className={cn("full-width faq__collapse", {
				"faq__collapse--active": isActive
			})}
			items={[{
				key: "1",
				label: <Typography.Text className={cn(TEXT_CLASSNAMES.LgSemibold, {
					[TEXT_CLASSNAMES.MdSemibold]: isLaptopM
				})}>{title}</Typography.Text>,
				children: (
					<Flex vertical className="gap-xs">
						{!!description.text &&	<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>{description.text}</Typography.Text>}
						{description?.list?.map((description) => (
							<Flex vertical className="gap-xs">
								<Typography.Text className={cn(TEXT_CLASSNAMES.MdSemibold, {
									[TEXT_CLASSNAMES.SmSemibold]: isLaptopM
								})}>{description.title}</Typography.Text>
								<Flex vertical component={description.type === "dots" ? "ul" : "ol"}>
									{description.list.map((text) => (
										<li>
											<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
												[TEXT_CLASSNAMES.SmRegular]: isLaptopM
											})}>{text}</Typography.Text>
										</li>
									))}
								</Flex>
							</Flex>
						))}
						{!!description.finalText && <Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.SmRegular]: isLaptopM
						})}>{description.finalText}</Typography.Text>}
					</Flex>
				)
			}]}
		/>
	);
};
