import { useSize } from "ahooks";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";

import { PromotionalCode } from "@/entities/plan";
import {
	BackgroundPreLogin,
	LoginWrapper
} from "@/entities/viewer";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { Spinner } from "@/shared/ui";
import { useBehavior } from "../model";
import { ComingSoonPlanType, EnterprisePlanType, SubPlans } from "./PlanType";

import "./Plan.scss";

export const Plan = () => {
	const { isMobileL, isLaptopM } = useMediaQueries();
	const size = useSize(() => document.querySelector(".login-wrapper"));

	const padding = isMobileL ? 32 : 120;

	const {
		loading,
		planParams,
		startUsing,
		startTrial,
		isShowTrial,
		onChange,
		possibleSubscriptions,
		logout
	} = useBehavior();

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				title=""
				questionText=""
				actionText=""
				action={() => {}}
				isChildrenFullHeight
			>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", overflowY: "scroll", width: "100%", height: "100%", maxHeight: (size?.height ?? 0) - padding }}>
					<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
						[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopM,
						[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileL
					})}>Choose your plan</Typography.Text>
					<div style={{ height: 16 }} />
					<PromotionalCode subscriptionId={planParams.termType} />
					<Flex style={{ marginTop: 24 }} vertical className="gap-xs">
						{possibleSubscriptions.isPending
							? <Spinner />
							: <SubPlans activePlanParams={planParams} onChange={onChange} />
						}
						<ComingSoonPlanType />
						<EnterprisePlanType />
					</Flex>
					<Flex vertical className="gap-xs">
						<Flex vertical={isMobileL} className="gap-xs" style={{ marginTop: 24 }}>
							<Button
								className="full-width"
								disabled={!planParams}
								type="primary"
								onClick={startUsing}
								loading={loading}
							>
								Continue
							</Button>
							<Button
								className="full-width"
								onClick={logout}
								loading={loading}
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Sign out</Typography.Text>
							</Button>
						</Flex>
						{isShowTrial && (
							<Button loading={loading} type="primary" onClick={startTrial}>
								Start trial
							</Button>
						)}
					</Flex>
				</div>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
