import { action, atom } from "@reatom/core";
import { callErrorAction } from "@/entities/notification";
import { InvitationVerifyParams, TRegisterKey, verifyInvitationResource } from "@/shared/api/auth";

export const invitationErrorAtom = atom<null | string>(null, "invitationErrorAtom");
export const invitationCodeAtom = atom<null | TRegisterKey>(null, "invitationCodeAtom");

export const invitationAction = action(async (ctx, params: InvitationVerifyParams) => {
	try {
		const { data } = await verifyInvitationResource(params);

		invitationCodeAtom(ctx, data);
	} catch (err) {
		invitationErrorAtom(ctx, "Oops, something went wrong");
		callErrorAction(ctx, err);
	}
});
