import "./PageHeader.scss";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import { LandingContent } from "@/shared/ui";

type Props = {
	headerText: string;
	italicHeaderText: string;
	description?: string;
}

export const PageHeader: FC<Props> = ({ italicHeaderText, headerText, description }) => {
	const { isTablet, isLaptopM, isMobileXL } = useMediaQueries();
	return (
		<Flex justify="center" className="page-header full-width">
			<LandingContent>
				<Flex vertical justify="center" align="center" className="page-header__content gap-s text-center">
					<Typography.Text className={cn("text-center", "page-header--title", TEXT_CLASSNAMES.LPHeadlineH1, {
						[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopM,
						[TEXT_CLASSNAMES.LPHeadlineH3]: isTablet,
						[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileXL
					})}>
						{headerText} <span className="page-header--italic">{italicHeaderText}</span>
					</Typography.Text>
					{!!description && (
						<Typography.Text className={TEXT_CLASSNAMES.MdRegular}>{description}</Typography.Text>
					)}
				</Flex>
			</LandingContent>
		</Flex>
	);
};
