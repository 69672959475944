import { reatomAsync, withDataAtom, withErrorAtom, withStatusesAtom } from "@reatom/async";

import { callErrorAction } from "@/entities/notification";
import {
	getAuthCustomerSubscriptionResource,
	getPaymentCredential,
	getPaymentDetails,
	getPossibleSubscriptionsResource,
	getSubscriptionPaymentHistoryResource
} from "@/shared/api/payment";
import { centsToDollars } from "@/shared/methods";

export const getCurrentPlanAction = reatomAsync(async () => {
	const { data: userPlan } = await getAuthCustomerSubscriptionResource();
	const { data: possibleSubscriptions } = await getPossibleSubscriptionsResource();

	return {
		...userPlan,
		plan_amount: centsToDollars(userPlan.plan_amount),
		...possibleSubscriptions[1],
		prices: possibleSubscriptions[1].prices.map((price) => ({
			...price,
			unit_amount: centsToDollars(price.unit_amount)
		}))
	};
})
	.pipe(
		withStatusesAtom(),
		withDataAtom(),
		withErrorAtom((ctx, err) => {
			callErrorAction(ctx, err);
		})
	);

export const getCurrentHistoryPlanAction = reatomAsync(async () => {
	const { data: history } = await getSubscriptionPaymentHistoryResource();
	const { data: possibleSubscriptions } = await getPossibleSubscriptionsResource();

	return history.map((el) => ({
		...el,
		amount: centsToDollars(el.amount),
		...possibleSubscriptions[1],
		prices: possibleSubscriptions[1].prices.map((price) => ({
			...price,
			unit_amount: centsToDollars(price.unit_amount)
		}))
	}));
})
	.pipe(
		withStatusesAtom(),
		withDataAtom([]),
		withErrorAtom((ctx, err) => {
			callErrorAction(ctx, err);
		})
	);

export const getPaymentDetailsAction = reatomAsync(() => getPaymentDetails())
	.pipe(
		withStatusesAtom(),
		withDataAtom(null, (_ctx, res) => res.data),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err))
	);

export const getPaymentCredentialsAction = reatomAsync(() => getPaymentCredential())
	.pipe(
		withStatusesAtom(),
		withDataAtom(null, (_ctx, res) => res.data),
		withErrorAtom((ctx, err) => callErrorAction(ctx, err))
	);
