import eth from "@/assets/partnership/eth.svg";
import ethBlack from "@/assets/partnership/eth_black.svg";
import ewor from "@/assets/partnership/ewor.svg";
import eworBlack from "@/assets/partnership/ewor_black.svg";
import nvidia from "@/assets/partnership/nvidia.svg";
import nvidiaBlack from "@/assets/partnership/nvidia_black.svg";
import opium from "@/assets/partnership/opium.svg";
import opiumBlack from "@/assets/partnership/opium_black.svg";
import quokka from "@/assets/partnership/quokka.svg";
import quokkaBlack from "@/assets/partnership/quokka_black.svg";

export const PARTNERSHIP = [
	{
		img: ewor,
		imgBlack: eworBlack,
		title: "Investors",
		description: "Supporting Blooper as strategic sponsors, helping us scale and innovate faster"
	},
	{
		img: eth,
		imgBlack: ethBlack,
		title: "Member of ETH AI Center",
		description: "Proud to be part of ETH’s network, connecting with leading AI researchers and innovator"
	},
	{
		img: nvidia,
		imgBlack: nvidiaBlack,
		title: "Part of NVIDIA Inception program",
		description: "Gaining access to AI expertise and resources to enhance Blooper’s technological development."
	},
	{
		img: quokka,
		imgBlack: quokkaBlack,
		title: "Studio film production",
		description: "Gaining access to AI expertise and resources to enhance Blooper’s technological development."
	},
	{
		img: opium,
		imgBlack: opiumBlack,
		title: "Studio film production",
		description: "Partnering to optimize creative processes and bring innovative tools to production teams."
	}
];
