import { FC } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper } from "@/entities/viewer";

import { LoginForm } from "./LoginForm";

import "./Login.scss";

export const Login: FC = () => {
	const navigate = useNavigate();
	const params = new URLSearchParams(window.location.search);
	const from = params.get("user_redirect_from");
	const isFromInstagram = from === "instagram";

	const handleRedirectToRegister = () => {
		if (from) {
			navigate({
				pathname: "/register",
				search: createSearchParams({
					user_redirect_from: from ?? ""
				}).toString()
			});
		} else {
			navigate("/register");
		}
	};

	const handleRedirectToRecover = () => {
		navigate("/recover-password");
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				action={handleRedirectToRegister}
				title="Sign in"
				questionText="Don’t have an account?"
				actionText="Sign up"
			>
				<LoginForm
					handleRedirectToRecover={handleRedirectToRecover}
					isFromInstagram={isFromInstagram}
				/>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
